body, html {
    /*width: 100vw;*/
    /*height: 100vh;*/
    background-image: url('../img/reenbg.jpg');
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.wrapper {
    height: 100%;
    color: #FFF;
    font-size: 25px;
}

.topleft {
    top: 30px;
    margin: 0px auto;
    width: 120px;
}

.topleft .nav-logo img{
    width: 100%;
}

.bottomleft {
    position: absolute;
    bottom: 30px;
    left: 16px;
}

.middle {
    position: absolute;
    top: 50%;
    padding-top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.middle h1{
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 20px;
    
}

.middle .subscribe-footer{
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

/* ==========================================================================
    #LOGIN / REGISTER
============================================================================= */
.login-container{
    background: #f0f0f0; 
}

.login-form{
    width: 480px;
    max-height: 700px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -moz-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -webkit-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -o-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    padding: 70px 90px 70px 90px;
    text-align: center;
}

.login-logo{
    width: 120px;
    margin: auto;
    margin-bottom: 20px;
}

.login-logo img{
    width: 100%;
}

.login-form h1{
    color: #333;
    margin-bottom: 15px;
}

.login-form p{
    color: #666;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
}

.login-form strong{
    font-weight: 600;
}

.form-container{
  margin: 30px 0px 30px 0px;
}

.custom-2 {
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
       -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-family: 'Open Sans', sans-serif;
}
.custom-2:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}
.custom-2::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.custom-2:-ms-input-placeholder {
  color: #999;
}
.custom-2::-webkit-input-placeholder {
  color: #999;
}

.btn-login {
  color: #fff;
  background-color: #FF404C;
  border-color: #FF404C;
  margin: 20px 0px 30px 0px;
  padding: 6px 60px;
    border-radius: 10px;
}
.btn-login:focus,
.btn-login.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-login:hover {
  color: #fff;
  background-color: #FF404C;
  border-color: #FF404C;
}
.btn-login:active,
.btn-login.active,
.open > .dropdown-toggle.btn-login {
  color: #fff;
  background-color: #FF404C;
  border-color: #FF404C;
}

/* ==========================================================================
   	MEDIA QUERIES MODULE.
   ========================================================================== */
@media (max-width:992px) {  
.topleft {
     width: 100px;
}
    
.middle h1{
    font-size: 35px;
}
    
.middle .subscribe-footer{
    width: 100%;
    margin: auto;
    margin-top: 30px;
}
    
.middle .newsletter-box .button {
  	width: 40%;
    font-size: 11px;
}
    
 .middle .newsletter-box .input {
  	width: 65%;
  	height: 38px;
  	background: #FDFCFB;
  	border: none;
  	font-family: inherit;
  	color: #737373;
  	letter-spacing: 1px;
  	text-indent: 5%;
  	font-size: 11px;
	font-family: 'Open Sans', sans-serif;
}
    
}

@media (max-width:576px) { 
.middle h1{
    font-size: 24px;
    line-height: 1.2;
}
    
.login-form{
    width: 100%;
    height: 700px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -moz-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -webkit-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    -o-box-shadow: 0 0 10px rgba(204,204,204,0.6);
    padding: 100px 30px 100px 30px;
    text-align: center;
}
    
.login-logo{
    margin-bottom: 10px;
}
    
.form-container{
  margin: 20px 0px 20px 0px;
}

}

@media (max-width:450px) { 
.middle h1{
    font-size: 24px;
    line-height: 1.2;
}
  
.middle {
    position: absolute;
    top: 30%;
    left: 20px;
    right: 20px;
    transform: translate(0%, 0%);
    text-align: center;
}
    
}

