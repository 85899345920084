/*.coming-soon-container {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}*/

.coming-soon-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coming-soon-title {
    font-weight: 600;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.coming-soon-text {
    color: white;
}

.comming-soon-notify {
    width: 40%;
}

.coming-soon-error-message {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    color: #cc5965;
}

.coming-soon-text-italic {
    color: #d9d9d9;
    font-style: italic;
}

.coming-soon-text-large {
    color: white;
    font-size: large;
}